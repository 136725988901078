import React, { FC } from 'react';
import Select from 'react-select';
import { Country } from '../axiosApi/models';
import intl from 'react-intl-universal';
import { sortCollection } from '../common/utils';
import { SORT_TYPES } from '../common/constants';

type CountrySelectProps = {
  handleCountryChange: any,
  selectedCountry: any,
  countries: any,
  inputCountry: any,
  isClearable?: boolean
};

const CountrySelect: FC<CountrySelectProps> = ({ handleCountryChange, selectedCountry, countries, inputCountry, isClearable = false }) => {

  const countriesSortedByName = sortCollection(countries, "label", SORT_TYPES.ASC);

  return (
    <Select
      id="addressCountry"
      name="addressCountry"
      getOptionLabel={e => intl.get(e.labelKey)}
      getOptionValue={e => e.id}
      placeholder={intl.get('addressForm.placeholder.country')}
      value={(countries != null && selectedCountry != null && inputCountry != '' ) ? (countries.find((country) => country.labelKey === selectedCountry)) : ''}
      onChange={handleCountryChange}
      isLoading={countries == null}
      options={countriesSortedByName}
      isClearable={isClearable}
    />
  );
}

export default CountrySelect;