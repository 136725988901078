import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { HiOutlinePlus } from 'react-icons/hi';
import { FaSearch } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountrySelect from '../../components/CountrySelect';
import ModalCalendar from './ModalCalendar';
import RequestSelect from './RequestSelect';
import TimeOffSearchBar from './TimeOffSearchBar';
import { PermissionsGate}  from '../PermissionsGate';
import { SCOPES } from 'common/permissions';
import { ENTITY_TYPE_EMPLOYEE } from 'common/constants';
import RequestStatusSelect from './RequestStatusSelect';

const HRCalendarNavbar = ({ handleCountryChange, selectedCountry, countries, input, handleCalendarEventDetailOpen, isCalendarEventDetailOpen, closeCalendarEventDetail, nonWorkingDay, requestType, requestStatus,onSearch, updateEvents, HRView }) => {


    const [idRequest, setIdRequest] = useState(null);
    const [idStatus, setIdStatus] = useState(null);
    const [queryString, setQueryString] = useState<string>('');

    const handleSearchButton = () => {
        onSearch(queryString, idStatus, idRequest);
    };

    const handleRequestChange = (e) => {        
        if (e !== null) {
            setIdRequest(e.id);
        } else {
            setIdRequest(null);
        }
    };

    const handleStatusChange = (e) => {
        if (e !== null) {
            setIdStatus(e.id);
        } else {
            setIdStatus(null);
        }
    };

    return (
        <nav className={nonWorkingDay ? 'navbar container-fluid mb-5' : 'navbar container-fluid filters gap-3 mb-5'}>
            {
                nonWorkingDay ?
                <>
                    <CountrySelect 
                        handleCountryChange={handleCountryChange}
                        selectedCountry={selectedCountry}
                        countries={countries}
                        inputCountry={input.country.name}
                        isClearable={true}
                    />
                    <button className='btn btn-primary p-1 plusButton' type='button' id='addHoliday' data-tooltip-id='tooltipButton' onClick={handleCalendarEventDetailOpen} ><HiOutlinePlus className='plusIcon'/></button>
                    <ReactTooltip 
                        id='tooltipButton'
                        place='left'
                        content={intl.get('timeOffCalendarNavbar.addHoliday.button')}
                    />
                    <ModalCalendar isOpen={isCalendarEventDetailOpen} closeModal={closeCalendarEventDetail} eventId={null} updateEvents={updateEvents}/>
                </>
                :
                <>{HRView ? 
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE_EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <TimeOffSearchBar queryString={queryString} setQueryString={setQueryString}/>
                    </PermissionsGate>
                    :
                    null 
                }
                    <RequestSelect requestType={requestType} selectedRequest={idRequest} handleRequestChange={handleRequestChange} />
                    <RequestStatusSelect requestStatus={requestStatus} selectedStatus={idStatus} handleStatusChange={handleStatusChange} isClearable={true} />

                    <button type="button" className="btn btn-outline-primary" onClick={handleSearchButton} ><FaSearch className="mb-1"></FaSearch>&nbsp;{intl.get('jiraIssueSearch.search.button')}</button>
                </>
            }
        </nav>
    )
}

export default HRCalendarNavbar;