import { MyTimesheetStatusComponent } from 'components/Reports/MyTimesheetStatusComponent';
import React, { useState, useEffect, FC } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import GenericCalendar from '../../components/Time Off/GenericCalendar';
import Spinner from '../Spinner';
import { formatDateTimeOff } from "../../common/utils";

export type EventType = {
    eventType: string,
    id: number,
    title: string,
    from: string,
    to: string | null,
    idEmployee: number | null,
    employeeName: string | null,
    employeeLastName: string | null,
    idWorkflowState: number | null,
    workflowStateName: string | null,
    changeLog: string | null,
    idTimeOffRequestType: number | null,
    timeOffRequestTypeName: string | null,
    idCountry: number | null,
    country: {
        name: string,
        "code": string,
        "phonePrefix": string,
        "id": number,
        "isDeleted": boolean
    }
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: EventType[] | any
};

export const DashboardComponent: FC = () => {

    const [events, setEvents] = useState<SearchResult>({
        totalRecords: null,
        queryResult: null
	});

    const [myTimeOffs, setMyTimeOffs] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});
    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const fetchEvents = async () => {
        setLoading(true);
        const today = new Date();
        const start = new Date(today);
        start.setFullYear(today.getFullYear() - 10);
        const end = new Date(today);
        end.setFullYear(today.getFullYear() + 10);
        const response = await api.eventApi.apiVversionEventAllGet("1", "", start, end, {}).then((response) => {
            if (response.data.data) {
                const renamedEvents = response.data?.data?.queryResult?.map(event => ({
                    ...event,
                    start: new Date(event.from),
                    end: new Date(event.to),
                    from: undefined, 
                    to: undefined
                }));
                const renamedEndDateEvents = renamedEvents.map(event => {
                    if (event.eventType === "TimeOff") {
                        const newEndDate = new Date(event.end);
                        newEndDate.setDate(newEndDate.getDate());
                        return { 
                            ...event, 
                            end: newEndDate 
                        };
                    } else {
                        return {
                            ...event,
                            end: event.start
                        };
                    };
                });
                setEvents({
                    totalRecords: renamedEndDateEvents.length,
                    queryResult: renamedEndDateEvents
                });
            }
            setLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('hRCalendar.toast.error.fetchEvents'), body: error.message });
            setLoading(false);
        });
    };


    const fetchMyApproveTimeOffs = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        f = [`state:${7}`];
        try {
            const response = await api.timeOffApi.apiVversionTimeOffSearchMineGet("1", "", 0 ,100  , "", "", f, {});
            if (response.status === 200) {
                if (response.data?.data?.queryResult) {
                    const formattedDates = response.data?.data?.queryResult.map(req => {
                        return {
                            ...req,
                            start: new Date(req.from),
                            end: new Date(req.to),
                            from: undefined, 
                            to: undefined
                        };
                    });
                    const renamedTimeOffEvents = formattedDates.map(event => {
                        const newEndDate = new Date(event.end);
                        newEndDate.setDate(newEndDate.getDate() + 1);
                        return { 
                            ...event, 
                            end: newEndDate, 
                            title: `${event.timeOffRequestTypeName} - ${event.employeeName} ${event.employeeLastName}`
                        };
                    });
                    setMyTimeOffs({
                        totalRecords: renamedTimeOffEvents.length,
                        queryResult: renamedTimeOffEvents
                    });
                } else {
                    setMyTimeOffs({
                        totalRecords: null,
                        queryResult: null
                    });
                }
            } else {
                toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: response.data.errors });
            };
        } catch (error) {
            toast.error({ header: intl.get('timeOff.toast.error.fetchRequests'), body: error.message });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchEvents();
        fetchMyApproveTimeOffs();
    }, []);    
    const components = [
        <MyTimesheetStatusComponent key="timesheet" errors={errors} setErrors={setErrors}/>,
        <div className='card' key="calendar">
            <div className='card-header'>
                <h4>{intl.get('dashboardComponent.calendar.title')}</h4>
            </div>
            <div className='card-body'>
                {
                    loading === true ?
                    <Spinner /> :
                    <GenericCalendar 
                        events={[...(events?.queryResult || [] ),...(myTimeOffs?.queryResult || [])]} 
                        isCalendarDashboard={true} 
                        updateEvents
                    />
                }
            </div>
        </div>
    ];

    const groupedComponents = [];
    for (let i = 0; i < components.length; i += 2) {
        groupedComponents.push(components.slice(i, i + 2));
    };

    return (
        <div className='container'>
            <div className='card mt-4'>
                <div className='container card-header'>
                    <h2 className="title">{intl.get('dashboardComponent.title')}</h2>
                </div>
                <div className='container card-body'>
                    {groupedComponents.map((group, index) => (
                        <div className="row" key={index}>
                            {group.map((component, idx) => (
                                <div className="col-md-6" key={idx}>
                                    {component}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};